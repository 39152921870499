import Header from "../components/core/Header";

function MainLayout({ children }) {
    return (
        <div className="flex h-screen flex-col">
            <Header />
                <main className="mx-auto w-full flex-1 px-3 py-4 sm:py-12 sm:px-6 lg:px-8">
                    {children}
                </main>
        </div>
    )
}

export default MainLayout;