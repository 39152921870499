import moment from "moment";
import { Spinner } from "react-activity";
import useReportUrl from "../../hooks/useReportUrl";
import { RiBookOpenLine } from "react-icons/ri";

function File({ name, updated_at, handleOpen }) {
    const { url, loading } = useReportUrl(name);
    const lastUpdateDate = moment(updated_at).fromNow();

    return (
        <button onClick={handleOpen(url)} className="bg-primary py-4 px-6 text-white border border-zinc-700 rounded-md flex items-center gap-2 transition duration-300 hover:shadow-lg hover:shadow-black">
            <div className="flex-1 flex flex-col items-start gap-1">
                <h2 className="font-bold">{name}</h2>
                <div className="text-xs text-zinc-400">Last updated {lastUpdateDate}</div>                
            </div>
            {loading ? <Spinner color="white" /> : <RiBookOpenLine size={21} color="white" />}
        </button>
    )
}

export default File;