import { createContext, useContext, useState } from "react";

const ReaderContext = createContext();

export const ReaderProvider = ({ children }) => {
    const [opened, setOpened] = useState(false);
    const [source, setSource] = useState(null);

    function toggle(url) {
        setSource(url ?? null);
        setOpened(!!url);
    }

    const value = {
        opened,
        source,
        toggle
    }

    return (
        <ReaderContext.Provider value={value}>{children}</ReaderContext.Provider>
    )
}

export const useReader = () => {
    const context = useContext(ReaderContext);
    if (typeof context === "undefined") throw new Error('useReader must be used inside ReaderContext.');
    return context;
}

export function withReader(Component) {
    return props => {
        return <ReaderProvider><Component {...props} /></ReaderProvider>;
    }
}