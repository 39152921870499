import classNames from "classnames";

function Input(props) {
    const { 
        label, 
        containerStyle="",
        ...otherProps 
    } = props;

    const style = classNames("space-y-2", containerStyle);

    return (
        <div className={style}>
            {label && <label className="text-white font-semibold">{label}</label>}
            <input className="w-full py-2 px-5 text-white block bg-transparent border border-white border-opacity-20 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-md outline-none" {...otherProps} />
        </div>
    )
}

export default Input;