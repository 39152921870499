import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStateChange, useClient } from "react-supabase";
import { toast } from "react-toastify";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const client = useClient();
    const [user, setUser] = useState();

    useEffect(() => {
        const session = client.auth.session();
        setUser(session?.user ?? null);
    }, [client.auth])

    useAuthStateChange((event, session) => {
        if (event === "PASSWORD_RECOVERY") {
            const params = new URLSearchParams(location.hash.slice(1));
            navigate(`/password-reset?${params}`);
        } else {
            setUser(session !== null ? session.user : null);
        }
    })

    const login = async ({ email, password }) => {
        const { error } = await client.auth.signIn({ email, password });
        if (error) toast(error.message);
    }

    const logout = async () => {
        await client.auth.signOut();
    }

    const resetPassword = async (mail) => {
        if (!mail && !user) return toast("Provide email to sent link.")
        const email = mail ?? user.email;
        const { error } = await client.auth.api.resetPasswordForEmail(email);
        if (error) {
            console.error(error.message);
            return toast("Unable to send password reset link.");
        }

        toast(`Sent reset link to ${email}`);
        if (user) await logout();
    }

    const value = {
        user,
        loading: typeof user === "undefined",
        isLoggedIn: typeof user !== "undefined" && user !== null,
        login,
        resetPassword,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (typeof context === "undefined") throw new Error('useAuth must be used within AuthProvider');
    return context;
}

export const withAuth = (Component) => {
    return props => {
        return <AuthProvider><Component {...props} /></AuthProvider>;
    }
}