import CenteredLayout from "../../layouts/CenteredLayout";
import Button from "../ui/Button";

function AskAuthScreen() {
    return (
        <CenteredLayout>
            <Button link to="/auth">Login to continue</Button>
        </CenteredLayout>    
    )
}

export default AskAuthScreen;