import { useEffect, useState } from "react";
import { useClient } from "react-supabase";
import { useAuth } from "../contexts/Auth";

function useReportUrl(filename) {
    if (typeof filename === "undefined") throw new Error('Must specify an `filename`.')
    const client = useClient();
    const { user } = useAuth();
    const [url, setUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!user) return;

        setLoading(true);
        client.storage
            .from('reports')
            .createSignedUrl(`${user.id}/${filename}`, 20)
            .then(response => {
                setUrl(response.signedURL);
                setError(response.error);
                setLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setLoading(false);
            });
    }, [user, filename])

    return { url, loading, error };
}

export default useReportUrl;