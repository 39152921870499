import { useAuth } from "../contexts/Auth";
import LoadingScreen from "../components/screens/LoadingScreen";
import GoToDashboard from "../components/screens/GoToDashboard";
import Input from "../components/ui/Input";
import { Link } from "react-router-dom";
import { RiFileShield2Fill } from "react-icons/ri";
import { useState } from "react";
import { validate } from "email-validator";
import { Spinner } from "react-activity";

function Auth() {
    const { user, loading: loadingUser, login, resetPassword } = useAuth();    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    if (loadingUser) return <LoadingScreen />;
    if (user) return <GoToDashboard />;

    const handleInput = (setValue) => (event) => setValue(event.target.value);

    const handleLogin = async (event) => {
        event.preventDefault();
        setLoading(true);
        await login({ email, password });
        setLoading(false);
    }

    const handleForgotPassword = async (event) => {
        event.preventDefault();

        if (!validate(email)) { 
            return setError("Provide a valid email.");
        }
        setError("");
        await resetPassword(email);
    }

    return (
        <main className="flex h-screen flex-row-reverse">
            <section className="flex items-start w-full px-4 mx-auto md:px-0 md:items-center md:w-1/3 z-10">
                <div className="-ml-4 bg-primary py-4 flex items-center justify-center text-white gap-2 text-lg">
                    <RiFileShield2Fill size={36} /> <span>Report Vault</span>
                </div>
            </section>
            <section className="md:w-2/3 px-4 md:px-0 md:flex justify-center md:border-r md:border-zinc-800">
                <div className="w-full max-w-sm min-w-min mx-auto my-auto py-4 md:py-9 md:w-7/12">
                    <h2 className="text-white text-2xl font-semibold">Sign In</h2>
                    <p className="mt-1 text-zinc-400 text-sm">Login with your company email.</p>
                    {error && (
                        <div className="my-6">
                            <p className="border-l-2 border-red-500 text-red-500 text-sm px-4">{error}</p>
                        </div>
                    )}                    
                    <div className="my-6">
                        <form acceptCharset="UTF-8" onSubmit={handleLogin}>
                            <Input containerStyle="mb-4" label="Email" type="email" onChange={handleInput(setEmail)} autoFocus />
                            <Input containerStyle="mb-4" label="Password" type="password" onChange={handleInput(setPassword)} />
                            <div className="mb-8">
                                <button type="button" onClick={handleForgotPassword} className="text-sm text-zinc-400 hover:text-blue-500">Forgot password?</button>
                            </div>
                            {loading 
                            ? (
                                <div className="flex items-center justify-center">
                                    <Spinner color="white" />
                                </div>
                            )
                            : (
                                <button className="w-full py-1.5 bg-white text-primary font-semibold rounded-md flex items-center justify-center" type="submit">
                                    Continue
                                </button>  
                            )}  
                        </form>
                    </div>
                    <div className="mt-10 mb-6">
                        <hr className="h-0 border-t border-zinc-800 mt-sm" />
                        <div className="-mt-2 flex items-center justify-center">
                            <span className="bg-primary px-2 text-xs text-zinc-400">Need Help?</span>
                        </div>
                    </div>

                    <p className="text-white">Reach us at &nbsp;<a className="text-blue-500" href="mailto:sales@consainsights.com">sales@consainsights.com</a>.</p>
                </div>
            </section>
        </main>
    )
}

export default Auth;