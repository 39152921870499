import classNames from "classnames";
import { Link } from "react-router-dom";

function Button(props) {
    const { link, style, children, className="", ...otherProps } = props;
    const styles = style || classNames("border border-orange-600 rounded py-2 px-5 font-semibold text-white duration-300 hover:border-white", className);

    if (link) {
        return <Link className={styles} {...otherProps}>{children}</Link>
    }
    return <button className={styles} {...otherProps}>{children}</button>
}

export default Button;