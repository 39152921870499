import { Spinner } from "react-activity";
import CenteredLayout from "../../layouts/CenteredLayout";

function LoadingScreen() {
    return (
        <CenteredLayout>
            <Spinner color="white" />
        </CenteredLayout>
    )
}

export default LoadingScreen;