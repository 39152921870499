import { createClient } from "@supabase/supabase-js";

let supabase;
if (typeof supabase === "undefined") {
    supabase = createClient(
        "https://idlrhrdxfmedvystmkej.supabase.co", 
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlkbHJocmR4Zm1lZHZ5c3Rta2VqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjAzODQ5MDAsImV4cCI6MTk3NTk2MDkwMH0.f48uJaXCA_ThUG_MM2BLyTmg4bUlnqogQPz_tBxR8I4"
    );
}

export default supabase;