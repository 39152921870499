import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { RiFileShield2Fill } from "react-icons/ri";
import { useAuth } from "../../contexts/Auth";

function Header() {
    const menuRef = useRef();
    const { user, resetPassword, logout } = useAuth();
    const [menu, setMenu] = useState(false);
    const avatarText = (user?.email ?? "A")[0].toUpperCase();

    const menuStyles = classNames("absolute top-12 right-0 border border-zinc-800 bg-primary shadow-lg shadow-black rounded-md transition duration-300 origin-top-right", {
        "scale-100": menu,
        "scale-0": !menu
    });

    useEffect(() => {
        function handleClick(event) {
            if (event.target.parentNode !== menuRef.current) setMenu(false);
        }

        window.addEventListener("click", handleClick);

        return () => {
            window.removeEventListener("click", handleClick);
        }
    })
    
    return (
        <div className="relative z-40 border-b border-zinc-800 bg-primary px-3 text-primary sm:px-6 lg:px-8">
            <header className="relative mx-auto max-w-7xl">
                <div className="flex items-center pt-3 pb-2 md:pt-8 md:pb-7 gap-2">
                    <div className="flex items-center">
                        <div className="w-10 h-10 flex items-center justify-center">
                            <RiFileShield2Fill size={32} color="white" />
                        </div>
                    </div>
                    <div className="flex flex-grow items-center">
                        <span className="text-white">Report Valut</span>
                    </div>
                    <div className="flex flex-none items-center">
                        <div ref={menuRef} className="relative">
                            <button onClick={() => setMenu(prev => !prev)} className="w-10 h-10 rounded-full bg-gradient-to-br from-green-500 to-pink-500 flex items-center justify-center text-white font-bold">
                                {avatarText}   
                            </button>

                            <div className={menuStyles}>
                                <ul className="divide-y divide-zinc-800">
                                    <li><button onClick={() => resetPassword()} className="w-full text-white whitespace-nowrap px-5 py-2 hover:bg-zinc-900">Reset Password</button></li>
                                    <li><button onClick={logout} className="w-full text-white whitespace-nowrap px-5 py-2 hover:bg-zinc-900">Logout</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="-mb-px flex space-x-3 overflow-x-auto sm:space-x-0">
                </div>
            </header>
        </div>        
    )
}

export default Header;