import { useState } from "react";
import { Spinner } from "react-activity";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useClient } from "react-supabase";
import CenteredLayout from "../../layouts/CenteredLayout";
import Input from "../ui/Input";

function PasswordResetScreen() {
    const [searchParams] = useSearchParams();
    const client = useClient();    
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handlePasswordReset = async (event) => {
        event.preventDefault();
        const accessToken = searchParams.get('access_token');

        if (!accessToken) {
            return setError("Invalid access token.");
        }
        if (password.length < 6) {
            return setError("Password must contain atleast 6 characters.");
        }
        if (password !== confirmPassword) {
            return setError("Passwords didn't match.");
        }

        setError("");
        setLoading(true);

        const { error } = await client.auth.api.updateUser(accessToken, {
            password
        });

        setLoading(false);

        if (error) {
            return setError("Unable to reset password. Check your network and try again...");
        }

        navigate('/dashboard', { replace: true });
    }
    const handleInput = (setValue) => (event) => setValue(event.target.value);

    return (
        <CenteredLayout>
            <section className="md:w-2/3 px-4 md:px-0 md:flex justify-center">
                <div className="w-full max-w-sm min-w-min mx-auto my-auto py-4 md:py-9 md:w-7/12">
                    <h2 className="text-white text-2xl font-semibold">Reset Password</h2>
                    <p className="mt-1 text-zinc-400 text-sm">Use secure password with atleast 6 characters.</p>
                    {error && (
                        <div className="my-6">
                            <p className="border-l-2 border-red-500 text-red-500 text-sm px-4">{error}</p>
                        </div>
                    )}
                    <div className="my-6">
                        <form onSubmit={handlePasswordReset}>
                            <Input type="password" containerStyle="mb-4" label="New Password" onChange={handleInput(setPassword)} />
                            <Input type="password" containerStyle="mb-8" label="Confirm Password" onChange={handleInput(setConfirmPassword)} />
                            {loading 
                                ? (
                                    <div className="flex items-center justify-center">
                                        <Spinner color="white" />
                                    </div>
                                )
                                : (
                                    <button className="w-full py-1.5 bg-white text-primary font-semibold rounded-md flex items-center justify-center" type="submit">
                                        Continue
                                    </button>  
                                )}                  
                        </form>
                    </div>
                </div>
            </section>
        </CenteredLayout>
    )
}

export default PasswordResetScreen;