import CenteredLayout from "../../layouts/CenteredLayout";
import Button from "../ui/Button";

function GoToDashboard() {
    return (
        <CenteredLayout>
            <Button link to="/dashboard">Go to dashboard</Button>
        </CenteredLayout>
    )
}

export default GoToDashboard;