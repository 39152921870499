import { useEffect, useState } from "react";
import { useClient } from "react-supabase";
import { useAuth } from "../contexts/Auth";

function useReports() {
    const client = useClient();
    const { user } = useAuth();
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!user) return;

        setLoading(true);
        client.storage
            .from('reports')
            .list(user.id)
            .then(response => {
                if (response.data) setReports(filterFiles(response.data));
                setError(response.error);
                setLoading(false);
            })
            .catch(e => {
                setError(e.message)
                setLoading(false);
            });
    }, [user])

    const filterFiles = list => list.filter(file => !file.name.startsWith("."));

    return { reports, loading, error };
}

export default useReports;