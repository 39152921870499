import { Route, Routes } from "react-router-dom";
import GaurdedScreen from "./components/gates/GaurdedScreen";
import { AuthProvider } from "./contexts/Auth";
import Auth from "./routes/Auth";
import Home from "./routes/Home";
import Dashboard from "./routes/Dashboard";
import PasswordRecovery from "./routes/PasswordRecovery";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<GaurdedScreen><Home /></GaurdedScreen>} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/dashboard" element={<GaurdedScreen><Dashboard/></GaurdedScreen>} />
        <Route path="/password-reset" element={<PasswordRecovery />} />
      </Routes>    
      <ToastContainer />  
    </AuthProvider>
  );
}

export default App;
