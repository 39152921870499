import Input from "../components/ui/Input";
import MainLayout from "../layouts/MainLayout";
import { RiArrowRightUpLine } from "react-icons/ri";
import useReports from "../hooks/useReports";
import { Spinner } from "react-activity";
import File from "../components/ui/File";
import { useReader, withReader } from "../contexts/Reader";
import ReaderScreen from "../components/screens/ReaderScreen";
import { withAuth } from "../contexts/Auth";

function Dashboard() {
    const { reports, loading } = useReports();
    const { opened, source, toggle } = useReader();

    const openReader = (url) => () => {
        if (!opened) toggle(url);
    }

    const closeReader = () => toggle();

    return (
        <MainLayout>
            <div className="mx-auto h-full max-w-7xl">
                <div className="space-y-12">
                    <div className="grid grid-cols-3 gap-3">
                        <div className="col-span-1">
                            <Input type="text" placeholder="Search reports" />
                        </div>
                        <div className="col-span-2 flex justify-end">
                            <a className="bg-white rounded py-2 px-5 text-primary font-medium flex items-center justify-center gap-1" href="https://consainsights.com" rel="noreferrer nofollow noopener" target="_blank">
                                Browse Reports <RiArrowRightUpLine size={21} />
                            </a>
                        </div>
                    </div>

                    {loading && (
                        <div className="flex items-center justify-center">
                            <Spinner color="white" />
                        </div>
                    )}

                    {!loading && <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
                        {reports.map(report => <File key={report.id} {...report} handleOpen={openReader} />)}
                    </div>}                    
                </div>
            </div>

            <ReaderScreen open={opened} source={source} onClose={closeReader} />
        </MainLayout>
    )
}

export default withAuth(withReader(Dashboard));