import classNames from "classnames";
import { RiArrowDownCircleLine } from "react-icons/ri";
import ReactShadowRoot from "react-shadow-root";
import useReportContent from "../../hooks/useReportContent";
import LoadingScreen from "../screens/LoadingScreen";

function ReaderScreen({ open, onClose, source }) {
    const { content, loading } = useReportContent(source);

    const style = classNames("z-50 fixed top-0 left-0 right-0 bottom-0 duration-500 bg-primary flex flex-col", {
        "pointer-events-none translate-y-full opacity-0": !open,
        "bg-primary translate-y-0 opacity-1": open
    });

    const handleCopy = (event) => {
        event.preventDefault();
    }

    const handleContextMenu = (event) => {
        event.preventDefault();
    }

    return (
        <div className={style}>
            <div className="border-b border-zinc-800">
                <header className="h-16 mx-auto max-w-7xl bg-primary flex items-center">
                    <div className="flex-1"></div>
                    <div className="flex-1 flex items-center justify-center">
                        <h2 className="font-medium text-white">Report Viewer</h2>
                    </div>
                    <div className="flex-1 flex justify-end">
                        <button onClick={onClose} className="py-1 pl-3 pr-2 rounded-full flex items-center justify-center gap-1 bg-white text-primary text-sm font-medium">Close <RiArrowDownCircleLine size={18} /></button>
                    </div>
                </header>
            </div>
            <div className={classNames("overflow-y-auto flex-1", { "bg-white": !loading })}>
                {loading && <LoadingScreen />}
                <div onCopy={handleCopy} onContextMenu={handleContextMenu} className="mx-auto max-w-screen-md py-10 px-0 md:px-4 select-none">
                    <ReactShadowRoot mode="closed">
                        <style>{`figure { pointer-events: none; }`}</style>
                        <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    </ReactShadowRoot>
                </div>
            </div>
        </div>
    )
}

export default ReaderScreen;