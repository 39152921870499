import { useEffect } from "react";
import { useState } from "react";

function useReportContent(url) {
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!url) return;

        async function fetchContent() {
            setLoading(true);
            const response = await fetch(url);
            const data = await response.text();
            if (response.ok) {
                setContent(data);
            } else {
                setError(data);
            }
            setLoading(false);  
        }

        fetchContent();
    }, [url])

    return { content, loading, error };
}

export default useReportContent;