import { useAuth } from "../../contexts/Auth";
import AskAuthScreen from "../screens/AskAuthScreen";
import LoadingScreen from "../screens/LoadingScreen";

function GaurdedScreen({ children }) {
    const { user, loading } = useAuth();

    if (loading) return <LoadingScreen />;
    if (!user) return <AskAuthScreen />;

    return <>{children}</>
}

export default GaurdedScreen;